import { brandOnboardingConfig } from '~/constants/brandsOnboardingConfig';
import useCurrentUserDetails from '~/composables/useCurrentUserDetails';
import type { BrandOnboardingConfig } from '@tn/shared';

const findNextStep = (
  filteredBrandOnboardingConfig: BrandOnboardingConfig[],
  stepId: string
) => {
  return filteredBrandOnboardingConfig.find((step) => step.id === stepId);
};

const findLastCompletedStep = (
  filteredBrandOnboardingConfig: BrandOnboardingConfig[],
  onboardingData: any
) => {
  const firstStep = onboardingData
    ? filteredBrandOnboardingConfig.find((item) => item.order === 1)
    : brandOnboardingConfig.find((item) => item.order === 0);

  let lastCompletedStep = null;
  for (const step of filteredBrandOnboardingConfig) {
    if (!onboardingData) {
      return firstStep;
    }

    if ((onboardingData as Record<string, any>)[step.dataKey]) {
      lastCompletedStep = step;
    } else {
      break;
    }
  }
  return lastCompletedStep ?? firstStep;
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { brandUser } = useCurrentUserDetails();
  const brandStatusComposable = useBrandStatus();

  if (!brandUser) {
    return navigateTo('/login');
  }

  if (!process.client) {
    return;
  }

  const currentStepId = to.params.id as string;

  const filteredBrandOnboardingConfig = brandOnboardingConfig.filter(
    (step) => step.dataKey !== 'details'
  );
  const onboardingStore = useBrandOnboardingStore();

  const { brandStatus } = onboardingStore;

  onboardingStore.setBrandId(brandUser.brand.id);

  const brandStatusData =
    brandStatus ?? (await brandStatusComposable.getBrandStatus());

  const isCompleted = toRaw(brandStatusData?.onboarding?.completedAtTimeMs);
  const onboardingData = toRaw(brandStatusData?.onboarding);

  const user = await getCurrentUser();

  if (!user) {
    console.warn('No user data found, exiting middleware.');
    return;
  }

  if (!user.emailVerified) {
    if (to.name !== 'brands-onboarding-email-verification') {
      return navigateTo('/brands/onboarding/email-verification');
    }
    return;
  }

  if (
    to.name === 'brands-onboarding-email-verification' &&
    from.name === 'brands-onboarding-email-verification'
  ) {
    return;
  }

  if (isCompleted && user.emailVerified) {
    return navigateTo({
      name: 'brands-products',
    });
  }

  const lastCompletedStep = findLastCompletedStep(
    filteredBrandOnboardingConfig,
    onboardingData
  );
  const nextStepItem = findNextStep(
    filteredBrandOnboardingConfig,
    currentStepId
  );

  if (
    to.name === 'brands-onboarding-email-verification' &&
    !isCompleted &&
    user.emailVerified &&
    lastCompletedStep
  ) {
    return navigateTo({
      name: 'brands-onboarding-step',
      params: { id: lastCompletedStep.id },
    });
  }

  if (
    nextStepItem &&
    lastCompletedStep &&
    nextStepItem?.order > lastCompletedStep?.order + 1 &&
    user.emailVerified
  ) {
    return navigateTo({
      name: 'brands-onboarding-step',
      params: { id: lastCompletedStep.id },
    });
  }

  if (
    to.fullPath === '/brands/onboarding/details' &&
    lastCompletedStep &&
    onboardingData &&
    !isCompleted &&
    from.fullPath !== '/brands/onboarding/products-types' &&
    user.emailVerified
  ) {
    return navigateTo({
      name: 'brands-onboarding-step',
      params: { id: lastCompletedStep.id },
    });
  }
});
